// Device type configuration
const deviceTypeConfig = {
    "0": 'All-in-One Sensor',
    "1": 'Temperature Sensor',
    "2": 'Motion Detector',
    "3": 'Humidity Sensor',
    "4": 'Others',
    "10": 'Temperature Sensor',
    "11": 'Temperature Sensor',
    "12": 'Temperature Sensor',
    "13": 'Temperature Sensor',
    "14": 'Temperature Sensor',

};

export default deviceTypeConfig;
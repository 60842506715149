// Device mode configuration
const deviceModeConfig = {
    "0": 'All-in-One Sensor',
    "2": 'Motion Detector',
    "3": 'Humidity Sensor',
    "4": 'Others',
    "1": 'To be confirmed',
    "10": 'No AC',
    "11": 'User Control',
    "12": 'Central Control',
    "13": 'User+Central Control',
};

export default deviceModeConfig;
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AddIcon from "@mui/icons-material/Add";
import {AuthContext} from "../../context";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Components
import DeviceTable from "./DeviceTable/DeviceTable";
import projectsTableData from "./DeviceTable/components/projectsTableData";
import DeviceEditor from "./DeviceTable/components/DeviceEditor.js";
import PointEditor from "./DeviceTable/components/PointEditor";
import ChartModal from "./DeviceTable/components/chartModal";
import MapModal from "./DeviceTable/components/mapModal";

// Services
import AggregationService from "services/aggregation-service"
import DeviceService from "services/device-service";
import MeasurementService from "services/measurement-service";
import ReadingService from "services/reading-service";
import CpuMetric from "../dashboard/components/CpuMetric";

// Constants to handle both device editor and point editor actions
const ACTION_ADD = 'ADD';
const ACTION_MODIFY = 'MODIFY';
const ACTION_DELETE = 'DELETE';

const { columns: dCs, rows: dRs } = DeviceTable([]);
const { columns: pColumns, rows: pRows } = projectsTableData();

function Tables() {
    const authContext = useContext(AuthContext);
    const [dColumns, setDColumns] = useState(dCs);
    const [dRows, setDRows] = useState(dRs);
    const [deviceId, setDeviceId] = useState(0);
    const [deviceName, setDeviceName] = useState('');
    const [deviceType, setDeviceType] = useState(0);
    const [mqttTopic, setMqttTopic] = useState('');
    const [deviceLatitude, setDeviceLatitude] = useState(0);
    const [deviceLongitude, setDeviceLongitude] = useState(0);
    const [lastReading, setLastReading] = useState(0);
    const [openDeviceEditor, setOpenDeviceEditor] = useState(false);
    const [deviceEditorAction, setDeviceEditorAction] = useState('');
    const [pointId, setPointId] = useState(0);
    const [pointName, setPointName] = useState('');
    const [pointType, setPointType] = useState(0);
    const [chartNumber, setChartNumber] = useState(0);
    const [accessRole, setAccessRole] = useState(0);
    const [openPointEditor, setOpenPointEditor] = useState(false);
    const [pointEditorAction, setPointEditorAction] = useState('');
    // const [openChartModal, setOpenChartModal] = useState(false);  // State for chart modal
    // const [selectedDeviceId, setSelectedDeviceId] = useState(null);  // State to store the selected device ID
    const [openMapModal, setOpenMapModal] = useState(false);  // State for the map modal
    const [locatedDevice,setLocatedDevice] = useState(null); // details of the selected device for their location
    // State variable for batch loading
    const [devices, setDevices] = useState([]);
    const [devicePoints, setDevicePoints] = useState({});
    const [loadingDetails, setLoadingDetails] = useState(true);
    const batchSize = 10;

    const navigate = useNavigate();

    const handleDeviceClick = (deviceId) => {
        // navigate(`/device-info/${deviceId}`);
        window.open(`/#/device-info/${deviceId}`, '_blank');
    };

    const handleDeviceLocationClick = (device) => {
        // this function injects the arguments of the selected device's location, and opens the mapModal for display
        setLocatedDevice(device);
        setOpenMapModal(true);
    }

    const viewCreateDevice = () => {
        setDeviceId(0);
        setDeviceName('');
        setDeviceType(0);
        setMqttTopic('');
        setDeviceLatitude(1.3102731537435788);
        setDeviceLongitude(103.7803697688016);
        setDeviceEditorAction(ACTION_ADD);
        setOpenDeviceEditor(true);
    }

    const createDevice = () => {
        const payload = {
            name: deviceName,
            type: deviceType,
            topic: mqttTopic,
            latitude: latitude,
            longitude: longitude
        };
        DeviceService.addDevice(payload).then(() => {
            setDeviceName('');
            setDeviceType(0);
            setMqttTopic('');
            setDeviceLatitude(1.3102731537435788);
            setDeviceLongitude(103.7803697688016);
            listDevices();
            console.log('Device created successfully');
        }).catch((error) => {
            alert('Error creating device: ' + error.toString());
        });
    }

    const viewModifyDevice = (device) => {
        setDeviceId(device.deviceId)
        setDeviceName(device.deviceName);
        setDeviceType(device.deviceType);
        setMqttTopic(device.deviceMqttTopic);
        setDeviceLatitude(device.deviceLatitude);
        setDeviceLongitude(device.deviceLongitude);
        setDeviceEditorAction(ACTION_MODIFY);
        setOpenDeviceEditor(true);
    }

    const modifyDevice = () => {
        const payload = {
            name: deviceName,
            type: deviceType,
            topic: mqttTopic,
            latitude: deviceLatitude,
            longitude: deviceLongitude,
        };
        DeviceService.modifyDevice(deviceId, payload).then(() => {
            listDevices();
            console.log('Device modified successfully');
        }).catch((error) => {
            alert('Error modifying device: ' + error.toString());
        });
    }

    const viewDeleteDevice = (device) => {
        setDeviceId(device.deviceId)
        setDeviceName(device.deviceName);
        setDeviceType(device.deviceType);
        setMqttTopic(device.deviceMqttTopic);
        setDeviceLatitude(device.deviceLatitude);
        setDeviceLongitude(device.deviceLongitude);
        setDeviceEditorAction(ACTION_DELETE);
        setOpenDeviceEditor(true);
    }

    const deleteDevice = () => {
        DeviceService.deleteDevice(deviceId).then(() => {
            listDevices();
        }).catch((error) => {
            alert('Error deleting device: ' + error.toString());
        });
    }

    const viewAddPoint = (device) => {
        setDeviceId(device.deviceId);
        setPointName('');
        setPointType(0);
        setChartNumber(0);
        setAccessRole(0);
        setPointEditorAction(ACTION_ADD);
        setOpenPointEditor(true);
    }

    const addPoint = () => {
        const payload = {
            deviceId: deviceId,
            name: pointName,
            type: pointType,
            chartNumber: chartNumber,
            accessRole: accessRole
        };
        MeasurementService.addMeasurementPoint(payload).then(() => {
            setDeviceId(0);
            setPointName('');
            setPointType(0);
            setChartNumber(0);
            setAccessRole(0);
            listDevices();
        }).catch((error) => {
            console.error('Error adding measuring point:', error);
        });
    }

    const viewModifyPoint = (point) => {
        setDeviceId(point.deviceId);
        setPointId(point.pointId);
        setPointName(point.pointName);
        setPointType(point.pointType);
        setChartNumber(point.chartNumber);
        setAccessRole(point.accessRole);
        setPointEditorAction(ACTION_MODIFY);
        setOpenPointEditor(true);
    }

    const modifyPoint = () => {
        const payload = {
            deviceId: deviceId,
            name: pointName,
            type: pointType,
            chartNumber: chartNumber,
            accessRole: accessRole
        };
        MeasurementService.modifyMeasurementPoint(pointId, payload).then(() => {
            listDevices();
            console.log('Device modified successfully');
        }).catch((error) => {
            console.log('Error modifying measurement point: ' + error.toString());
            alert('Error modifying measurement point: ' + error.toString());
        });
    }

    const viewDeletePoint = (point) => {
        setDeviceId(point.deviceId);
        setPointId(point.pointId);
        setPointName(point.pointName);
        setPointType(point.pointType);
        setChartNumber(point.chartNumber);
        setAccessRole(point.accessRole);
        setPointEditorAction(ACTION_DELETE);
        setOpenPointEditor(true);
    }

    const deletePoint = () => {
        MeasurementService.deleteMeasurementPoint(deviceId, pointId).then(()=>{
            listDevices();
        }).catch((error) => {
            console.log('Error deleting measurement point: ' + error.toString());
            alert('Error deleting measurement point: ' + error.toString());
        });
    }

    const listDevices = async () => {
        try {
            // Fetch measurement points
            const res = await MeasurementService.listMeasurementPoints();
            const newDevicePoints = {};
            res.collection.forEach(point => {
                const device_id = "" + point.deviceId;
                if (!(device_id in newDevicePoints)) {
                    newDevicePoints[device_id] = [];
                }
                newDevicePoints[device_id].push(point);
            });
            setDevicePoints(newDevicePoints);

            // Fetch devices
            const accessibleDevice = authContext.address;
            const devicesListed = await DeviceService.listDevices(accessibleDevice);
            const filteredDevices = devicesListed.filter(device => device.deviceType >= 10);

            const deviceMap = {};
            filteredDevices.forEach(e => { deviceMap[e.deviceId + ""] = e });

            // --- Unable to use current credential as one-time password will be changed after login --- //
            // const jsonExceedCount = await AggregationService.getExceedingDayCount(authContext.email, authContext.password);
            const jsonExceedCount = await AggregationService.getExceedingDayCount(
                process.env.REACT_APP_ANALYTICS_API_USERNAME,
                process.env.REACT_APP_ANALYTICS_API_PASSWORD
            );
            const exceedCountData = JSON.parse(jsonExceedCount)
            const exceedCountMap = {};
            exceedCountData.forEach(e => {
                exceedCountMap[e.deviceId + ""] = e.Days_Exceed_Threshold
            });
            const lowerThresholdMap = {};
            exceedCountData.forEach(e => {
                lowerThresholdMap[e.deviceId + ""] = e.Threshold_Lower
            });

            setDevices(filteredDevices.map(device => ({
                ...device,
                lastReading: '...',
                threshold: lowerThresholdMap[device.deviceId + ""] ?? '25',
                daysGT25: exceedCountMap[device.deviceId + ""] ? exceedCountMap[device.deviceId + ""].toString() : '0'
            })));

            const currentTime = new Date();
            const endTime = currentTime.toISOString();
            const startTime4Hour = new Date(currentTime.getTime() - 2 * 60 * 60 * 1000).toISOString();
            const readings = await ReadingService.getReadings(startTime4Hour, endTime);
            const lastReadingMap = {};
            readings.forEach(e => {
                if (e.deviceId in deviceMap && deviceMap[e.deviceId + ""]['dateUpdated'] === e.dateTime) {
                    lastReadingMap[e.deviceId + ""] = e.pointData.value
                }
            });

            setDevices(filteredDevices.map(device => ({
                ...device,
                lastReading: lastReadingMap[device.deviceId + ""] ?? 'N/A',
                threshold: lowerThresholdMap[device.deviceId + ""] ?? '25',
                daysGT25: exceedCountMap[device.deviceId + ""] ? exceedCountMap[device.deviceId + ""].toString() : '0'
            })));

            // --- Start loading details in batches
            // loadDeviceDetails(filteredDevices, exceedCountMap, lowerThresholdMap);
        } catch (error) {
            alert('Error listing devices: ' + error.toString());
        }
    };
    const loadDeviceDetails = async (devices, exceedingMap, thresholdMap) => {
        setLoadingDetails(true);
        const currentTime = new Date();
        const endTime = currentTime.toISOString();
        const startTime2Hour = new Date(currentTime.getTime() - 2 * 60 * 60 * 1000).toISOString();

        for (let i = 0; i < devices.length; i += batchSize) {
            const batch = devices.slice(i, i + batchSize);
            const batchDeviceIds = batch.map(device => device.deviceId);

            // Fetch readings for the batch
            const readings = await ReadingService.getReadings(startTime2Hour, endTime);
            const lastReadingMap = {};
            readings.forEach(e => {
                if (batchDeviceIds.includes(e.deviceId) && devices.find(d => d.deviceId === e.deviceId)?.dateUpdated === e.dateTime) {
                    lastReadingMap[e.deviceId] = e.pointData.value;
                }
            });

            const updatedBatch = batch.map(device => ({
                ...device,
                lastReading: lastReadingMap[device.deviceId] || 'N/A',
                threshold: thresholdMap[device.deviceId + ""],
                daysGT25: exceedingMap[device.deviceId] || '0'
            }));

            setDevices(prevDevices => {
                const newDevices = [...prevDevices];
                updatedBatch.forEach(updatedDevice => {
                    const index = newDevices.findIndex(d => d.deviceId === updatedDevice.deviceId);
                    if (index !== -1) {
                        newDevices[index] = updatedDevice;
                    }
                });
                return newDevices;
            });

            // Small delay to prevent UI freezing
            await new Promise(resolve => setTimeout(resolve, 100));
        }

        setLoadingDetails(false);
    };

    useEffect(() => {
        listDevices();
    }, [authContext.role]);

    useEffect(() => {
        updateDeviceTable();
    }, [devices, devicePoints]);

    const updateDeviceTable = () => {
        const { columns: c1s, rows: r1s } = DeviceTable(
            devices,
            devicePoints,
            viewModifyDevice,
            viewDeleteDevice,
            viewAddPoint,
            viewModifyPoint,
            viewDeletePoint,
            handleDeviceClick,
            handleDeviceLocationClick,
            authContext.role,
        );
        setDColumns(c1s);
        setDRows(r1s);
        setOriginalData(r1s);
    };

    // search bar
    const [originalData, setOriginalData] = useState({})
    const [inputDeviceName, setInputDeviceName] = useState('');
    const inputRef = useRef(null);
    const handleKeyDown = (event) => {
        // console.log('user inputted into search bar');
        if (event.key === 'Enter') {
            // lose the focus within the searchbar when press enter
            event.preventDefault();
            event.target.blur();
            // Code that sends this search query to the table results
            if (inputDeviceName === "") {
                setDRows(originalData);
            } else {
                setDRows(findDevices(originalData,inputDeviceName));
            }
            // Log the input value or use it as needed
            // console.log('inputDeviceName value:', inputDeviceName);
        }
    };

    // find devices based on search query
    const findDevices = (dRows, inputDeviceId) => {
        return dRows.filter(device =>
            device.deviceName.props.children
                .toLowerCase().includes(inputDeviceId.toLowerCase()));
        //.topic.props.topic;
    }

    return (
        <DashboardLayout>
            <div style={{ display: "flex", flexDirection: "column", minHeight:"95vh", justifyContent: "space-between" }}>
                <div>
                    <DashboardNavbar />
                    <MDBox pt={6} pb={3}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                        // style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                    >
                                        <MDBox
                                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                        >
                                            <MDTypography variant="h6" color="white">
                                                Device Info
                                            </MDTypography>
                                            <MDBox
                                                style={{display: 'inline-flex', alignItems: 'center',gap: '1rem'}}>
                                                <MDInput
                                                    ref={inputRef}
                                                    style={{backgroundColor:"white",borderRadius:6,width:"18rem"}}
                                                    placeholder="Search for a device name (Enter):"
                                                    value={inputDeviceName}
                                                    onChange={(e) => setInputDeviceName(e.target.value)}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                {
                                                    authContext.role > 1 && (
                                                        <MDButton iconOnly={true} size="medium" onClick={viewCreateDevice}>
                                                            <AddIcon />
                                                        </MDButton>
                                                    )
                                                }
                                            </MDBox>
                                        </MDBox>
                                        {/*<MDBox pt={3}>*/}
                                        {/*    <MDTypography variant="caption" color="white" style={{ display: 'block' }}>*/}
                                        {/*        Note: For facilities with centrally controlled AC which require temperature adjustments, kindly contact ED through raising an EDD Service Request.*/}
                                        {/*    </MDTypography>*/}
                                        {/*</MDBox>*/}
                                        <DeviceEditor
                                            title="Create Device"
                                            open={openDeviceEditor}
                                            setOpen={setOpenDeviceEditor}
                                            deviceName={deviceName}
                                            setDeviceName={setDeviceName}
                                            deviceType={deviceType}
                                            setDeviceType={setDeviceType}
                                            mqttTopic={mqttTopic}
                                            setMqttTopic={setMqttTopic}
                                            deviceLatitude={deviceLatitude}
                                            setDeviceLatitude={setDeviceLatitude}
                                            deviceLongitude={deviceLongitude}
                                            setDeviceLongitude={setDeviceLongitude}
                                            addAction={createDevice}
                                            modifyAction={modifyDevice}
                                            deleteAction={deleteDevice}
                                            editorAction={deviceEditorAction}
                                        />
                                        <PointEditor
                                            open={openPointEditor}
                                            setOpen={setOpenPointEditor}
                                            pointName={pointName}
                                            setPointName={setPointName}
                                            pointType={pointType}
                                            setPointType={setPointType}
                                            chartNumber={chartNumber}
                                            setChartNumber={setChartNumber}
                                            accessRole={accessRole}
                                            setAccessRole={setAccessRole}
                                            addAction={addPoint}
                                            modifyAction={modifyPoint}
                                            deleteAction={deletePoint}
                                            editorAction={pointEditorAction}
                                        />
                                    </MDBox>
                                    <MDBox mx={3} pt={3}>
                                        <MDTypography variant="caption" color="secondary" style={{ display: 'block' }}>
                                            * Note: For facilities with centrally controlled AC which require temperature adjustments, kindly contact ED through raising an EDD Service Request.
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox pt={3}>
                                        <DataTable
                                            table={{ columns: dColumns, rows: dRows }}
                                            isSorted={true}
                                            // entriesPerPage={15}
                                            showTotalEntries={true}
                                            noEndBorder
                                            isLoading={loadingDetails}
                                        />
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </div>
                <Footer />
            </div>
            {/*<ChartModal*/}
            {/*    deviceId={selectedDeviceId}*/}
            {/*    open={openChartModal}*/}
            {/*    handleClose={() => setOpenChartModal(false)}*/}
            {/*/>*/}
            <MapModal
                device={locatedDevice}
                open={openMapModal}
                handleClose={() => setOpenMapModal(false)}
            />
        </DashboardLayout>
    );
}

export default Tables;
